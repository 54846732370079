@media only print {
    #audit-results {
      width: auto;
      height: auto;
      overflow: visible;
      /* display: flex !important; */
    }
 }

 @media not print {
  #audit-results {
     display: none;  
  }
 }
